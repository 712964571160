
const COMPANY_ITEMS = [
  { name: "Pampa Energía", tenant: "pampa" },
  { name: "Vista Energy", tenant: "vista" },
  { name: "Bekeu Pro", tenant: "tenant" },
];

const PRO_TENANTS = ["tenant"];

const MAX_FILE_SIZE = {
  MB1PDF: {
    // 1 MB - PDF
    name: "1 MB",
    size: 1048576,
    errorMsg:
      "El archivo subido no cumple los parámetros requeridos. Por favor adjunta el remito en formato .PDF y con un tamaño máximo de 1 MB.",
    extensions: ["pdf", "PDF"],
    format: ".pdf",
  },

  MB2ALL: {
    // 2 MB - PNG, JPG, PDF
    name: "2 MB",
    size: 2097152,
    errorMsg:
      "El archivo subido no cumple los paramétros requeridos. Por favor, adjuntá el remito en formato PNG, JPG o PDF y con un tamaño máximo de 2 MB.",
    extensions: ["png", "jpg", "jpeg", "pdf", "PNG", "JPG", "JPEG", "PDF"],
    format: ".pdf, .png, .jpg, .jpeg",
  },

  MB2ALLREQPROD: { // solicitud de producto
    // 2 MB - PNG, JPG, PDF
    name: "2 MB",
    size: 2097152,
    errorMsg:
      "El archivo subido no cumple los paramétros requeridos. Por favor, adjuntá una foto en formato PDF, JPG o PNG y con tamaño máximo de 2 MB.",
    extensions: ["png", "jpg", "jpeg", "pdf", "PNG", "JPG", "JPEG", "PDF"],
    format: ".pdf, .png, .jpg, .jpeg",
  },
};

const SORTBY = [
  { name: "Mas relevantes", value: "" },
  { name: "Menor precio", value: "min_price" },
  { name: "Mayor precio", value: "max_price" },
  { name: "Nuevos ingresos", value: "recent_product" },
  // { name: "Mejores vendedores", value: "4" },
  // { name: "Mejores productos", value: "5" },
]

const INITIAL_EMPTY_FILTERS = {
  isFirstLoad: true,
  resetPage: false,
  categories: { category_name: [], category_id: [] },
  brands: { brand_name: [], brand_id: [] },
  delivery_zones: { delivery_zone_name: [], delivery_zone_id: [] },
  price: [],
  currency_code: null,
  delivery_days: [],
  providers: { provider_name: [], provider_id:[] },
  providers_type: { provider_type_name: [], provider_type_id: [] },
  sort_by: "",
};

const storageFiltersVar = localStorage.getItem("storageFilters");

const INITIAL_STORAGED_FILTERS = {
  isFirstLoad: true,
  resetPage: false,
  categories: {
    category_name: (storageFiltersVar && JSON.parse(storageFiltersVar).categories && JSON.parse(storageFiltersVar).categories.category_name)
      ? JSON.parse(storageFiltersVar).categories.category_name
      : [],
    category_id: (storageFiltersVar && JSON.parse(storageFiltersVar).categories && JSON.parse(storageFiltersVar).categories.category_id)
      ? JSON.parse(storageFiltersVar).categories.category_id
      : [],
  },
  brands: {
    brand_name: (storageFiltersVar && JSON.parse(storageFiltersVar).brands && JSON.parse(storageFiltersVar).brands.brand_name)
      ? JSON.parse(storageFiltersVar).brands.brand_name
      : [],
    brand_id: (storageFiltersVar && JSON.parse(storageFiltersVar).brands && JSON.parse(storageFiltersVar).brands.brand_id)
      ? JSON.parse(storageFiltersVar).brands.brand_id
      : [],
  },
  delivery_zones: {
    delivery_zone_name: (storageFiltersVar && JSON.parse(storageFiltersVar).delivery_zones && JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_name)
      ? JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_name
      : [],
    delivery_zone_id: (storageFiltersVar && JSON.parse(storageFiltersVar).delivery_zones && JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_id)
      ? JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_id
      : [],
  },
  price: storageFiltersVar ? JSON.parse(storageFiltersVar).price : [],
  currency_code: storageFiltersVar
    ? JSON.parse(storageFiltersVar).currency_code
    : 0,
  delivery_days: storageFiltersVar
    ? JSON.parse(storageFiltersVar).delivery_days
    : [],
  providers: {
    provider_name: (storageFiltersVar && JSON.parse(storageFiltersVar).providers && JSON.parse(storageFiltersVar).providers.provider_name)
      ? JSON.parse(storageFiltersVar).providers.provider_name
      : [],
    provider_id: (storageFiltersVar && JSON.parse(storageFiltersVar).providers && JSON.parse(storageFiltersVar).providers.provider_id)
      ? JSON.parse(storageFiltersVar).providers.provider_id
      : [],
  },
  providers_type: {
    provider_type_name: (storageFiltersVar && JSON.parse(storageFiltersVar).providers && JSON.parse(storageFiltersVar).providers.provider_type_name)
      ? JSON.parse(storageFiltersVar).providers_type.provider_type_name
      : [],
    provider_type_id: (storageFiltersVar && JSON.parse(storageFiltersVar).providers && JSON.parse(storageFiltersVar).providers.provider_type_id)
      ? JSON.parse(storageFiltersVar).providers_type.provider_type_id
      : [],
    },
    sort_by: storageFiltersVar ? JSON.parse(storageFiltersVar).sort_by : "",
};

export { COMPANY_ITEMS, PRO_TENANTS, MAX_FILE_SIZE, SORTBY, INITIAL_EMPTY_FILTERS, INITIAL_STORAGED_FILTERS};
